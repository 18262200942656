import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createEmployee, getRole, getDepartment} from '../employee-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../employee-list/core/QueryResponseProvider'
import {Employee, initialEmployee as initialValues} from '../employee-list/core/_models'

const roleDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Employee name is required'),
  rolesId: Yup.string().required('Employee role is required'),
  departmentsId: Yup.string().required('Department is required'),
  email: Yup.string().required('Employee email is required').email('Invalid email format'),
  contactNumber: Yup.string()
    .required('Employee mobile is required')
    .matches(/^\d{13}$/, 'Invalid phone number format'),
  // paymentRate: Yup.number()
  //   .required('Employee salary is required')
  //   .positive('Salary must be a positive number')
  //   .integer('Salary must be an integer'),
})

const RoleDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Employee>(initialValues)
  const navigate = useNavigate()
  const [formImage, setFormImage] = useState<File | null>(null)

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setData({
          ...data,
          profile: reader.result,
        })
      }
    }
    const files = e.target.files
    if (files) {
      setFormImage(files[0])
      reader.readAsDataURL(files[0])
    }
  }

  const {currentUser} = useAuth()
  const type = currentUser?.branchId
  const [loading, setLoading] = useState(false)

  const formik = useFormik<Employee>({
    initialValues,
    validationSchema: roleDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId

      const formData = new FormData()
      if (formImage) formData.append('profile', formImage)

      Object.entries(updatedData).forEach(([key, value]) => formData.append(key, value))

      createEmployee(formData).then((res) => {
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/employee', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })

  const [role, setRole] = useState<any>([])
  useEffect(() => {
    getRole(type).then((res: any) => {
      setRole(res?.data?.data)
    })
  }, [])

  const [department, setDepartment] = useState<any>([])
  useEffect(() => {
    getDepartment(type).then((res: any) => {
      setDepartment(res?.data?.data)
    })
  }, [])
  console.log('formik', formik)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Employee</h3>
        </div>
      </div>
      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-8'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>Profile Image</label>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  {typeof data?.profile === 'string' ? (
                    <div
                      className='image-input-wrapper w-225px h-225px'
                      style={{backgroundImage: `url(${data.profile})`}}
                    ></div>
                  ) : (
                    <div
                      className='image-input-wrapper w-225px h-125px'
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                      }}
                    ></div>
                  )}
                  <label
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    data-bs-dismiss='click'
                    title='Change profile image'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>
                    <input
                      type='file'
                      name='avatar'
                      onChange={imageHandler}
                      accept='.png, .jpg, .jpeg'
                    />
                  </label>
                  {/* <!--end::Edit button--> */}
                  {/* <!--begin::Remove button--> */}
                  {data.profile && (
                    <span
                      className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='remove'
                      onClick={() => {
                        setData({
                          ...data,
                          profile: null,
                        })
                      }}
                      data-bs-toggle='tooltip'
                      data-bs-dismiss='click'
                      title='Remove profile image'
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                  )}
                </div>
                {data.profile ? (
                  ''
                ) : (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>Please upload Profile </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Role</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('rolesId')}
                  >
                    <option value=''>Select a Role</option>
                    {role.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.rolesId && formik.errors.rolesId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.rolesId}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Department</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Department'
                    {...formik.getFieldProps('departmentsId')}
                  >
                    <option value=''>Select a Department</option>
                    {department.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.departmentName}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.departmentsId && formik.errors.departmentsId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.departmentsId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Name'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Email</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Email'
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Contact Number</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Contact Number'
                    {...formik.getFieldProps('contactNumber')}
                  />
                  {formik.touched.contactNumber && formik.errors.contactNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.contactNumber}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Blood Group</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Select Blood Group'
                    {...formik.getFieldProps('bloodGroup')}
                  >
                    <option value=''>Select Blood Group</option>
                    <option value='A+'>A+</option>
                    <option value='A-'>A-</option>
                    <option value='B+'>B+</option>
                    <option value='B-'>B-</option>
                    <option value='AB+'>AB+</option>
                    <option value='AB-'>AB-</option>
                    <option value='O+'>O+</option>
                    <option value='O-'>O-</option>
                  </select>
                  {formik.touched.bloodGroup && formik.errors.bloodGroup && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.bloodGroup}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Password</label>
                  <input
                    type='password'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Password'
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.password}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Payment Type</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Payment Type'
                    {...formik.getFieldProps('paymentType')}
                  >
                    <option value=''>Select a Payment</option>
                    <option value='1'>Rate per hour</option>
                    <option value='2'>Salary</option>
                  </select>
                  {formik.touched.paymentType && formik.errors.paymentType && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.paymentType}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Salary</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Salary'
                    {...formik.getFieldProps('paymentRate')}
                  />
                  {formik.touched.paymentRate && formik.errors.paymentRate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.paymentRate}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-form-label fw-bold fs-6'>Address (Optional)</label>
                  <textarea
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee address'
                    {...formik.getFieldProps('address')}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.address}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RoleDetails
