import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {Process, initialProcess as initialValues} from '../process-list/core/_models'
import {
  createProcess,getSalesOrder,getProcessType,getPriority,
getMaterial,getMachine, getProcessStatus
} from '../process-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../process-list/core/QueryResponseProvider'


// Validation schema
const processDetailsSchema = Yup.object().shape({
  // date: Yup.date().required('Process Date is required'),
  processDetail: Yup.array().of(
    Yup.object().shape({
      // processtypeId: Yup.number().required('Process Type is required'),
      // materialId: Yup.number().required('Material is required'),
      // machineId: Yup.number().required('Machine is required'),
      // priorityId: Yup.number().required('Priority is required'),
      // processTime: Yup.string().required('Process Time is required'),
    })
  ),
})
// Define a Product type
interface SalesOrder {
  id: number
  orderId: number
  quantity: number
  ordercategoryId: number
  name: string
  priority: string
  date: any
  customer: {
    customerName: number
  }
  product: {
    productName: number
    styleNumber:string
  }
  ordercategory: {
    categoryName: number
  }
}

const ProcessDetails = () => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [machine, setMachine] = useState([])
  const [processType, setProcessType] = useState([])
  const [status, setProcessStatus] = useState([])
  const [priority, setPriority] = useState([])
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Process>(initialValues)
  const navigate = useNavigate()
  const [salesOrder, setSalesOrder] = useState<SalesOrder[]>([])
  const [material, setMaterialList] = useState([])
  const [orderIdForModal, setOrderIdForModal] = useState(null); // State to manage orderId for the modal
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [orderStatusId, setOrderStatusId] = useState(''); 
  const openModalWithOrderId = (orderId:any) => {
    setShowModal(true); // Open modal
    setOrderIdForModal(orderId); // Set orderId for the modal
  };

  const getCurrentDate = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0') // January is 0
    const day = String(currentDate.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const getCurrentTime = () => {
    const currentTime = new Date()
    const hours = String(currentTime.getHours()).padStart(2, '0')
    const minutes = String(currentTime.getMinutes()).padStart(2, '0')
    return `${hours}:${minutes}`
  }

  useEffect(() => {
    getMaterial().then((res) => {
      setMaterialList(res?.data?.data)
    
    })
  }, [])
  useEffect(() => {
    getSalesOrder().then((res) => {
      setSalesOrder(res?.data?.data)
    console.log("setSalesOrder",res?.data?.data);
    
    })
  }, [])

  useEffect(() => {
    getProcessType().then((res) => {
      setProcessType(res?.data?.data)
    console.log("setSalesOrder",res?.data?.data);
    
    })
  }, [])
  useEffect(() => {
    getMachine().then((res) => {
      setMachine(res?.data?.data)
    console.log("getMachine",res?.data?.data);
    
    })
  }, [])
  useEffect(() => {
    getPriority().then((res) => {
      setPriority(res?.data?.data)
    console.log("setPriority",res?.data?.data);
    
    })
  }, [])
  useEffect(() => {
    getProcessStatus().then((res) => {
      setProcessStatus(res?.data?.data)
    console.log("setPriority",res?.data?.data);
    
    })
  }, [])
  useEffect(() => {
    if (orderStatusId === '1') {
      setShowModal(true);
    }
  }, [orderStatusId]);
  return (
    <div className='card mb-5 mb-xl-10'>
    <Formik
        initialValues={{
          ...initialValues,
          date: getCurrentDate(),
          processTime: getCurrentTime(),
        }}
        enableReinitialize={true}
        validationSchema={processDetailsSchema}
        onSubmit={(values, { resetForm }) => {
          const updatedValues = {
            ...values,
            process: values.processDetail,
            // createdBy: currentUser?.userId,
          };

          createProcess(updatedValues)
            .then((res) => {
              console.log('process', res?.message);
              resetForm({
                ...initialValues,
                // date: getCurrentDate(),
                // processTime: getCurrentTime(),
              });
              setData(initialValues);
              navigate('/process', { replace: true });
              refetch();
              setLoading(false);
              showAlert(res?.message, res?.status);
            })
            .catch((error) => {
              console.error('Error creating process:', error);
            })
            .finally(() => {
              setLoading(false);
            });
        }}
        >
        {({values, setFieldValue}) => (
          <Form>
            <div
              className='card-header border-0'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Add Production Process</h3>
              </div>
            </div>
            <div id='kt_account_profile_details'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Order Id / Sytle Number </label>'
             
                               <Field
                                      as='select'
                                      name='orderId'
                                      className='form-control form-control-lg form-control-solid'
                                      onChange={(e: any) => {
                                        const selectedProductId = e.target.value;
                                        const selectedProductData = salesOrder.find(
                                          (item: any) => item.id === parseInt(selectedProductId, 10)
                                        );
                            
                                        if (selectedProductData) {
                                          setFieldValue(`orderId`, selectedProductId);
                                          setFieldValue(`quantity`, selectedProductData.quantity.toString());
                                          setFieldValue(`name`, selectedProductData.name);
                                          setFieldValue(`orderDate`, selectedProductData.date);
                                          setFieldValue(`customerName`, selectedProductData.customer.customerName);
                                          setFieldValue(`productName`, selectedProductData.product.productName);
                                          setFieldValue(`ordercategoryId`, selectedProductData.ordercategory.categoryName);
                                          setFieldValue(`priority`, selectedProductData.priority);
                                   
                                        } else {
                                          setFieldValue(`orderId`, '');
                                          setFieldValue(`quantity`, '');
                                          setFieldValue(`name`, '');
                                          setFieldValue(`orderDate`, '');
                                          setFieldValue(`customerName`, '');
                                          setFieldValue(`productName`, '');
                                          setFieldValue(`ordercategoryId`, '');
                                          setFieldValue(`priority`, '');
                                         
                                        }
                                      }}
                                    >
                                      <option value=''>Select Order</option>
                                      {salesOrder.map((item: any, i: any) => (
                                        <option key={i} value={item.id}>
                                          {item.orderId}- {item.product.styleNumber}
                                        </option>
                                      ))}
                                    </Field>

                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Customer Name</label>
                      <Field
                        name='customerName'
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Customer Name'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='customerName'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>

                  </div>
                </div>


                
                <div className='row mb-6'>
                  <div className='row'>
                  <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Product Name</label>
                      <Field
                        name='productName'
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Product Name'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='productName'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Quantity</label>
                      <Field
                        name='quantity'
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Quantity'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='quantity'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>


                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Date</label>
                      <Field
                        name='orderDate'
                        type='date'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Order Date'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='orderDate'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Order Category</label>
                      <Field
                        name='ordercategoryId'
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Order Category'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='ordercategoryId'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='row'>
                
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Priority</label>
                      <Field
                        name='priority'
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='priority'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='priority'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Process Date</label>
                     <Field
                name='date'
                type='date'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Date'
              />
                      <ErrorMessage
                        className='fieldError'
                        name='date'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>

                  </div>
                </div>


                <div className='row mb-6'>
                  <div className='row'>
             

                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Details</label>
                      <Field
                        as='textarea'
                        name='description'
                        className='form-control form-control-lg form-control-solid'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='description'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
               
                  </div>
                </div>
              </div>
              <div className='row mb-6 card-footer d-flex justify-content'>
                <div className='table-container'>
                <FieldArray
  name='processDetail'
  render={(arrayHelpers) => (
    <table className="table">
      <thead>
        <tr>
          <th>Process Type</th>
          <th>Material</th>
          <th>Machine</th>
          <th>Priority</th>
          <th>Process Time</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {values.processDetail.map((processDetail, index) => (
          <tr key={index}>
            <td>
              <Field
                as='select'
                name={`processDetail[${index}].processtypeId`}
                className='form-control'
              >
                <option value=''>Select Type</option>
                {processType.map((item: any, i: any) => (
                  <option key={i} value={item.id}>
                    {item.processTypeName}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                className='fieldError'
                name={`processDetail[${index}].processtypeId`}
                component="div"
              />
            </td>
            <td>
              <Field
                as='select'
                name={`processDetail[${index}].materialId`}
                className='form-control'
              >
                <option value=''>Select Material</option>
                {material.map((item: any, i: any) => (
                  <option key={i} value={item.id}>
                    {item.materialName}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                className='fieldError'
                name={`processDetail[${index}].materialId`}
                component="div"
              />
            </td>
            <td>
              <Field
                as='select'
                name={`processDetail[${index}].machineId`}
                className='form-control'
              >
                <option value=''>Select Machine</option>
                {machine.map((item: any, i: any) => (
                  <option key={i} value={item.id}>
                    {item.headName}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                className='fieldError'
                name={`processDetail[${index}].machineId`}
                component="div"
              />
            </td>
            <td>
              <Field
                as='select'
                name={`processDetail[${index}].priorityId`}
                className='form-control'
              >
                <option value=''>Select Priority</option>
                {priority.map((item: any, i: any) => (
                  <option key={i} value={item.id}>
                    {item.priorityName}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                className='fieldError'
                name={`processDetail[${index}].priorityId`}
                component="div"
              />
            </td>
            <td>
              <Field
                type='time'
                className='form-control'
                name={`processDetail[${index}].processTime`}
              />
              <ErrorMessage
                className='fieldError'
                name={`processDetail[${index}].processTime`}
                component="div"
              />
            </td>
            <td>
              {index === 0 ? (
                <button
                  type="button"
                  onClick={() => arrayHelpers.push({})}
                  className="btn btn-primary btn-sm"
                >
                  Add
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => arrayHelpers.remove(index)}
                  className="btn btn-danger btn-sm"
                >
                  Remove
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )}
/>

                </div>
              </div>
              <div className='d-flex justify-content-center py-6 px-9 mb-8'>
  <div className='form-check form-check-custom form-check-solid form-check-lg'>
    <div className='col-md-12 mb-4 mb-md-0'>
      <label className='form-label required fw-bold fs-6'>
        Is Completed ?
      </label>
      {/* Render status values as a horizontal list */}
      <div className='d-flex'>
        {status.map((item: any, i: any) => (
          <div key={i} className='form-check me-4'>
            <input
              className='form-check-input'
              type='checkbox'
              value={item.id}
              id={`statusCheckbox${i}`}
              name='orderstatusId'
            />
            <label className='form-check-label' htmlFor={`statusCheckbox${i}`}>
              {item.statusName}
            </label>
          </div>
        ))}
      </div>
      {/* Render error message */}
      <ErrorMessage
        className='fieldError'
        name='orderstatusId'
        render={(msg) => (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{msg}</div>
          </div>
        )}
      />
    </div>
  </div>
</div>

            </div>
         
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading ? 'Save Changes' : 'Please wait...'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && (
        <div className="modal" tabIndex={-1} role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Complete Process Form</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* Form fields */}
                {/* Populate orderId */}
                <label htmlFor="orderId">Order ID:</label>
                <input type="text" id="orderId" name="orderId" value={orderIdForModal ?? ''} disabled />
                {/* Other form fields */}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                {/* Submit button */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    
  )
}

export default ProcessDetails
