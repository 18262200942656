/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {recentProject} from './core/request'
import {listRecentProject, initialValues} from './core/model'
import Avatar from 'react-avatar'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import { EmployeeListWrapper } from '../../modules/apps/employee/employee-list/EmployeeList'

type Props = {
  className: string
}

const ProjectTable: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()
  const [data, setData] = useState<listRecentProject>(initialValues)
  const [projects, setProjects] = useState<any>([])
  const updateData = (fieldsToUpdate: Partial<listRecentProject>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    recentProject().then((res: any) => {
      setProjects(res.data.Data)
      console.log('test111', res.data.Data)
    })
  }, [])
  const viewSalary_generateDetails = (Id: any) => {
    navigate('/salary_generate-form/view/' + Id)
  }
  const {currentUser} = useAuth()
  const [claimaccess, setclaimcaccess] = useState<any>([])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Employee List</span>
         
        </h3>
        
      </div>
      {/* end::Header */}

      <EmployeeListWrapper />
    
    </div>
  )
}

export {ProjectTable}
