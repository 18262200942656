import {ID, Response} from '../../../../../../_metronic/helpers'
export type SalesOrder = {
  id?: ID
  primaryId?: number
  date: any
  totalQuantity: any
  totalTax: any
  totalDiscount: any
  totalAmount: any
  paidAmount: any
  description: string
  customerId: number
  isCredit: boolean
  salesOrderDetail: [
    {
      productId: number
      styleNumber: string
      colorId: number
      sizeId: number
      unitId: number
      amount: number
      taxId: number
      taxValue: number
      discount: number
      discountValue: number
      total: number
      quantity: number
      orderstatusId: number
      priorityId: number
    }
  ]

  createdBy: number
  message?: string
  status?: number
}

export type SalesOrderQueryResponse = Response<Array<SalesOrder>>

export const initialSalesOrder: SalesOrder = {
  date: '',
  totalQuantity: 0,
  totalTax: 0,
  totalDiscount: 0,
  totalAmount: 0,
  paidAmount: 0,
  description: '',
  customerId: 0,
  isCredit: false,
  salesOrderDetail: [
    {
      productId: 0,
      styleNumber: '',
      colorId: 0,
      sizeId: 0,
      unitId: 0,
      amount: 0,
      taxId: 0,
      taxValue: 0,
      discount: 0,
      discountValue: 0,
      total: 0,
      quantity: 0,
      orderstatusId: 0,
      priorityId: 0,
    }
  ],
  createdBy: 0,
}
export type UpdateSalesOrder = {
  id?: ID
  primaryId?: number
  date: any
  totalQuantity: any
  totalTax: any
  totalDiscount: any
  totalAmount: any
  paidAmount: any
  description: string
  customerId: number
  isCredit: boolean
  salesOrderDetail: [
    {
      productId: number
      styleNumber: string
      colorId: number
      sizeId: number
      unitId: number
      amount: number
      taxId: number
      taxValue: number
      discount: number
      discountValue: number
      total: number
      quantity: number
      orderstatusId: number
      priorityId: number
    }
  ]

  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateSalesOrderQueryResponse = Response<Array<UpdateSalesOrder>>

export const initialUpdateSalesOrder: UpdateSalesOrder = {
  date: '',
  totalQuantity: 0,
  totalTax: 0,
  totalDiscount: 0,
  totalAmount: 0,
  paidAmount: 0,
  description: '',
  customerId: 0,
  isCredit: false,
  salesOrderDetail: [
    {
      productId: 0,
      styleNumber: '',
      colorId: 0,
      sizeId: 0,
      unitId: 0,
      amount: 0,
      taxId: 0,
      taxValue: 0,
      discount: 0,
      discountValue: 0,
      total: 0,
      quantity: 0,
      orderstatusId: 0,
      priorityId: 0,
    }
  ],
}
