import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createAttendance, getEmployee} from '../attendance-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../attendance-list/core/QueryResponseProvider'
import {Attendance, initialAttendance as initialValues} from '../attendance-list/core/_models'
import {setRoleAccess} from '../../role-access/role-setting-list/core/_requests'
//Validation
const attendanceDetailsSchema = Yup.object().shape({
  employeeId: Yup.string().required('Attendance  name is required'),
  date: Yup.string().required('Date is required'),
  checkIn: Yup.string().required('Signin Time is required'),
})

//Function Starts
const AttendanceDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Attendance>(initialValues)
  const navigate = useNavigate()

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()

  const [loading, setLoading] = useState(false)
  //formik Starts

  const getCurrentDate = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0') // January is 0
    const day = String(currentDate.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const getCurrentTime = () => {
    const currentTime = new Date()
    const hours = String(currentTime.getHours()).padStart(2, '0')
    const minutes = String(currentTime.getMinutes()).padStart(2, '0')
    return `${hours}:${minutes}`
  }

  const formik = useFormik<Attendance>({
    initialValues: {
      ...initialValues,
      date: getCurrentDate(), // Set the initial value to the current date
      checkIn: getCurrentTime(), // Set the initial value to the current time
    },
    validationSchema: attendanceDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId
      createAttendance(updatedData).then((res: any) => {
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/attendance', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })

  // Get employee using type
  const [employee, setEmployee] = useState<any>([])
  useEffect(() => {
    getEmployee().then((res: any) => {
      setEmployee(res?.data?.data)
    })
  }, [])
  const handleCancel = () => {
    // Navigate to the previous page
    navigate(-1)
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Attendance </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/** Company Info */}
            {/** Profile Image */}

            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Employee Name</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Employee Name'
                    {...formik.getFieldProps('employeeId')}
                  >
                    <option value=''>Select Employee Name</option>
                    {employee.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.employeeName}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.employeeId && formik.errors.employeeId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.employeeId}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Date</label>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Date'
                    id='date'
                    {...formik.getFieldProps('date')}
                  />
                  {formik.touched.date && formik.errors.date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Check In</label>
                  <input
                    type='time'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Signin'
                    id='checkIn'
                    {...formik.getFieldProps('checkIn')}
                  />
                  {formik.touched.checkIn && formik.errors.checkIn && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button type='button' className='btn btn-secondary ms-2' onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AttendanceDetails
