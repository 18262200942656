import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewSalaryadvance} from '../salary-advance-list/core/_requests'
import {EditSalaryadvance} from '../salary-advance-form/EditSalaryadvance'

export const EditSalaryadvanceWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewSalaryadvance(id).then((res) => {
      setData(res.data)
    })
  }, [])
  if (data) {
    console.log('ddd', data.amount)
    return <EditSalaryadvance initialValues={data} />
  }
  return null
}
