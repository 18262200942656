import React, {FC, useContext, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {approveSalarygenerate} from '../salary-generate-list/core/_requests'
import {UpdateSalarygenerate} from '../salary-generate-list/core/_models'

type Props = {
  initialValues: UpdateSalarygenerate
}

export const EditSalarygenerate: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<UpdateSalarygenerate>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const handleApprove = async () => {
    setLoading(true)

    const approvedBy = currentUser?.userId // Assuming userId is a number
    const approvedStatus = '1' // Assuming it needs to be a string
    const approvedDate = new Date().toISOString().split('T')[0] // Format as "yyyy-MM-dd"

    console.log('Before API call:', {approvedBy, approvedStatus, approvedDate})

    const data = {
      approvedBy,
      approvedStatus,
      approvedDate,
    }
    console.log('Before API call:approvalData', data)
    try {
      // Set data to the API
      const res = await approveSalarygenerate(data, id)

      console.log('API Response:', res)
      console.log('mes', res?.data?.message)

      // Only handle the success response, no need to update the state
      navigate('/salary-generate', {replace: true})
      showAlert(res?.message, res?.status)
    } catch (error) {
      // Handle error
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  // ... (rest of the component)

  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header ' style={{padding: '0%'}}>
              <div className='col-lg-12 row'>
                <div className='card-title m-0 col-lg-4'>
                  <h3 className='fw-bolder m-0' style={{padding: '4%'}}>
                    Salary Generate Details
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card-title text-center m-0'>
        <h3 className='fw-bolder m-0 me-2'>
          Payroll posting sheet for -
          <b className='my-6' style={{color: 'red'}}>
            {data.salarysheet.month}
          </b>
          <br />
          <br />
          (Not Approved)
        </h3>
      </div>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'></div>
            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Amount</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark'>{data.salaryAmount}</a>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Loan Amount</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark'>{data.loanDeduct}</a>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Salary Amount</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark'>{data.salaryAmount}</a>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Net Salary</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark'>{data.netSalary}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          type='button'
          className='btn btn-primary'
          onClick={handleApprove}
          disabled={loading}
        >
          {!loading ? 'Approve' : 'Please wait...'}
        </button>
      </div>
    </>
  )
}

export default EditSalarygenerate
