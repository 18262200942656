import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewApprovalList} from '../salary-generate-list/core/_requests'
import EditSalarygenerate from './EditSalaryGenerate'

export const EditSalarygenerateWrapper = () => {
  const {id} = useParams()
  const [data, setdata] = useState<any>(null)
  useEffect(() => {
    viewApprovalList().then((res) => {
      console.log('wrappppres', res.data)
      console.log('wrapppp-data', data)

      setdata(res.data)
    })
  }, [])
  if (data) {
    return <EditSalarygenerate initialValues={data} />
  }
  return null
}
