import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {Process, initialProcess as initialValues} from '../process-list/core/_models'
import {
  createProcess,
getMaterial
} from '../process-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../process-list/core/QueryResponseProvider'

// Validation schema
const processDetailsSchema = Yup.object().shape({
  // ProcessDate: Yup.string().required('process Date is required'),
  processDetail: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string().required('Amount is required'),
      quantity: Yup.string().required('Quantity is required'),
    })
  ),
})
// Define a Product type
interface Product {
  id: number
  name: string
  price: number
  tax: {
    percentage: number
  }
}
interface Material {
  id: number
  name: string
  price: number
  tax: {
    percentage: number
  }
}
const EditProcess = () => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [customer, setCustomer] = useState([])

  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Process>(initialValues)
  console.log('initialValues', initialValues)
  const navigate = useNavigate()
  const [productList, setProductList] = useState<Product[]>([])
  const [material, setMaterialList] = useState<Material[]>([])
  const [selectedProcessType, setSelectedSalesType] = useState('');

  const [isCredit, setIsCredit] = useState<boolean>(false) // Initial value can be true or false based on your requirements
  const handleManualClick = () => {
    console.log('Before click - isCredit:', isCredit)
    setIsCredit(!isCredit)
    console.log('After click - isCredit:', isCredit)
  }



  useEffect(() => {
    getMaterial().then((res) => {
      setMaterialList(res?.data?.data)
    
    })
  }, [])


  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={processDetailsSchema}
        onSubmit={(values, {resetForm}) => {
        
          const updatedValues = {
            ...values,
            process: values.processDetail,
            createdBy: currentUser?.userId,
          }

          // Now you can submit the updated 'values' to the createSales function
          createProcess(updatedValues)
            .then((res) => {
              // Handle the response
              console.log('process', res?.message)
              resetForm({
                ...initialValues,
                values: {...initialValues},
              })
              setData(initialValues)
              navigate('/process', {replace: true})
              refetch()
              setLoading(false)
              showAlert(res?.message, res?.status)
            })
            .catch((error) => {
              console.error('Error creating sales:', error)
            })
            .finally(() => {
              setLoading(false)
            })
        }}
      >
        {({values, setFieldValue}) => (
          <Form>
            <div
              className='card-header border-0'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Add Production Process</h3>
              </div>
            </div>
            <div id='kt_account_profile_details'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Order Name </label>'
                      <Field
                        as='select'
                        name='orderId'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Customer Name'
                      >
                        <option value=''>Select Order</option>
                        {customer.map((item: any, i: any) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.orderId}
                            </option>
                          )
                        })}
                      </Field>
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Process Date</label>
                      <Field
                        name='date'
                        type='date'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Date'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='date'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Details</label>
                      <Field
                        as='textarea'
                        name='description'
                        className='form-control form-control-lg form-control-solid'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='description'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
               
                  </div>
                </div>
              </div>
              <div className='row mb-6 card-footer d-flex justify-content'>
                <div className='table-container'>
                  <FieldArray
                    name='processDetail'
                    render={(arrayHelpers) => {
                      console.log('arrayHelpers', values, arrayHelpers)
                      return (
                        <div>
                          {values.processDetail && values.processDetail.length > 0
                            ? values.processDetail.map((processDetail, index) => (
                                <div key={index} className='row mb-2 position-relative addon-group'>
                                  {/* {selectedSalesType === 'Product' && ( */}
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Process Type
                                    </label>
                                    <Field
                        as='select'
                        name={`processDetail[${index}].processtypeId`}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='  Process Type'
                      >
                        <option value=''>Select Type </option>
                        {customer.map((item: any, i: any) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.orderId}
                            </option>
                          )
                        })}
                      </Field>
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`processDetail[${index}].processtypeId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                      {/* )} */}
                                 
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                   Material's
                                    </label>

                                    <Field
                                      as='select'
                                      name={`processDetail[${index}].materialId`}
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='SKU Name'
                                    >
                                      <option value=''>Select Material</option>
                                      {material.map((item: any, i: any) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.materialName}
                                          </option>
                                        )
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`processDetail[${index}].materialId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                            
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Unit
                                    </label>
                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Tax Value'
                                      name={`processDetail[${index}].taxValue`}
                                      readOnly
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`processDetail[${index}].machineId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>                                           
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Process Time</label>
                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Process Time'
                                      name={`processDetail[${index}].processTime`}
                                      readOnly
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`processDetail[${index}].processTime`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                            
                                  {index === 0 ? (
                                    <div className='col-md-2 mb-2 mb-md-0'>
                                      <label className='form-label mt-14'></label>
                                      <Link to='#' className='add-btn'>
                                        <div
                                          onClick={() =>
                                            arrayHelpers.push({
                                              productId: 0,
                                              skuId: 0,
                                              sizeId: 0,
                                              colorId: 0,
                                              unitId: 0,
                                              styleNumber: '',
                                              amount: '',
                                              tax: '',
                                              taxValue: '',
                                              discount: '',
                                              discountValue: '',
                                              total: '',
                                              quantity: '',
                                            })
                                          }
                                          className='btn btn-primary btn-sm'
                                        >
                                          Add Sales
                                        </div>
                                      </Link>
                                    </div>
                                  ) : (
                                    <div className='col-md-2 mb-2 mb-md-0'>
                                      <label className='form-label mt-14'></label>
                                      <Link to='#' className='add-btn'>
                                        <div
                                          onClick={() => arrayHelpers.remove(index)}
                                          className='btn btn-danger btn-sm'
                                        >
                                          Remove
                                        </div>{' '}
                                      </Link>
                                    </div>
                                  )}
                                  <br />
                                  <br /> <br />
                                  <br /> <br />
                                  <hr />
                                </div>
                              ))
                            : null}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Total Calculations fields */}
            <div className='form-group row justify-content-end py-6 px-9'>
              <label className='form-label fw-bold fs-6 col-form-label col-lg-2 text-right col-sm-12'>
             order Status
              </label>
              <div className='col-lg-2 col-md-10 col-sm-12'>
                <Field
                  type='text'
                  className='form-control'
                  name='orderstatusId'
                 
                />
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading ? 'Save Changes' : 'Please wait...'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EditProcess
