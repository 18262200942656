import {ID, Response} from '../../../../../../_metronic/helpers'
export type Process = {
  id?: ID
  primaryId?: number
  date: any
  salesorderId: number
  orderstatusId: any
  description: string
  processDetail: [
    {
      processtypeId: number
      materialId: number
      machineId: number
      priorityId: number
      processTime: any
     
    }
  ]
  createdBy: number
  message?: string
  status?: number
}

export type ProcessQueryResponse = Response<Array<Process>>

export const initialProcess: Process = {
  date: '',
  salesorderId: 0,
  orderstatusId: '',
  description: '',
  processDetail: [
    {
      processtypeId: 0,
      materialId: 0,
      machineId: 0,
      priorityId: 0,
      processTime: '',
    },
  ],
 
  createdBy: 0,
}
export type UpdateProcess = {
  id?: ID
  primaryId?: number
  date: any
  salesorderId: number
  orderstatusId: any
  description: string
  processDetail: [
    {
      processtypeId: number
      materialId: number
      machineId: number
      priorityId: number
      processTime: any
     
    }
  ]
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateProcessQueryResponse = Response<Array<UpdateProcess>>

export const initialUpdateProcess: UpdateProcess = {
  date: '',
  salesorderId: 0,
  orderstatusId: '',
  description: '',
  processDetail: [
    {
      processtypeId: 0,
      materialId: 0,
      machineId: 0,
      priorityId: 0,
      processTime: '',
    },
  ],
 
}
