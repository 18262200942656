// @ts-nocheck
import {Column} from 'react-table'
import {ProcessInfoCell} from './ProcessInfoCell'
import {ProcessTwoStepsCell} from './ProcessTwoStepsCell'
import {ProcessActionsCell} from './ProcessActionsCell'
import {ProcessCustomHeader} from './ProcessCustomHeader'
import {Process} from '../../core/_models'

const ProcessColumns: ReadonlyArray<Column<Process>> = [
  {
    Header: (props) => (
      <ProcessCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },

  {
    Header: (props) => (
      <ProcessCustomHeader tableProps={props} title='Production Id' className='min-w-85px' />
    ),
    accessor: 'productionId',
  },
  {
    Header: (props) => (
      <ProcessCustomHeader tableProps={props} title='Sales Order Id' className='min-w-125px' />
    ),
    accessor: 'salesorder.orderId',
  },
  // {
  //   Header: (props) => (
  //     <ProcessCustomHeader tableProps={props} title='Machine Name' className='min-w-125px' />
  //   ),
  //   accessor: 'coa.headName',
  // },
  {
    Header: (props) => (
      <ProcessCustomHeader tableProps={props} title='Date' className='min-w-95px' />
    ),
    accessor: 'date',
  },
  {
    Header: (props) => (
      <ProcessCustomHeader tableProps={props} title='Quantity' className='min-w-75px' />
    ),
    accessor: 'salesorder.quantity',
  },
  {
    Header: (props) => (
      <ProcessCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'orderstatus.name',
  },
  {
    Header: (props) => (
      <ProcessCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProcessActionsCell process={props.data[props.row.index]} />,
  },
]

export {ProcessColumns}
