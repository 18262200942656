import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../opening-balance-list/core/QueryResponseProvider'

import {
  Openingbalance,
  initialOpeningbalance as initialValues,
} from '../opening-balance-list/core/_models'
import {
  createOpeningbalance,
  getLedger,
  getCloseFinancialYear,
} from '../opening-balance-list/core/_requests'

const OpeningbalanceDetailsSchema = Yup.object().shape({
  openingDate: Yup.string().required('Opening Date is required'),
  financialyearId: Yup.string().required('Year is required'),
  openingBalance: Yup.array().of(
    Yup.object().shape({
      coaId: Yup.string().required('Account Name is required'),
    })
  ),
})

const OpeningbalanceDetails = () => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [ledger, setLedger] = useState([])
  const [financialyear, setFinancialYear] = useState([])
  const navigate = useNavigate()
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Openingbalance>(initialValues)

  useEffect(() => {
    getLedger()
      .then((res) => {
        setLedger(res?.data?.data)
      })
      .catch((error) => {
        console.error('Error fetching ledger:', error)
      })
  }, [])

  useEffect(() => {
    getCloseFinancialYear()
      .then((res) => {
        setFinancialYear(res?.data?.data)
      })
      .catch((error) => {
        console.error('Error fetching financial year:', error)
      })
  }, [])

  function getCurrentDate() {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={OpeningbalanceDetailsSchema}
        onSubmit={(values, {resetForm}) => {
          setLoading(true)
          const updatedValues = {
            ...values,
            openingBalance: values.openingBalance,
            createdBy: currentUser?.userId,
          }
          createOpeningbalance(updatedValues)
            .then((res: any) => {
              console.log('createOpeningbalance-res', res?.message)
              resetForm({
                ...initialValues,
                values: {...initialValues},
              })
              setData(initialValues)
              navigate('/opening-balance', {replace: true})
              refetch()
              setLoading(false)
              showAlert(res?.message, res?.status)
            })
            .catch((error) => {
              console.error('Error creating openingbalance:', error)
            })
            .finally(() => {
              setLoading(false)
            })
        }}
      >
        {({values}) => (
          <Form>
            <div
              className='card-header border-0'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Add Opening Balance</h3>
              </div>
            </div>

            <div id='kt_account_profile_details'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Financial Year</label>
                      <Field
                        as='select'
                        name='financialyearId' // Add a unique name for the Field
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Financial Year '
                      >
                        <option value=''>Select Financial</option>
                        {financialyear.map((item: any, i: any) => {
                          console.log('financialyear-drop', financialyear)
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </Field>

                      <ErrorMessage
                        className='fieldError'
                        name='financialyearId'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Date</label>
                      <Field
                        name='openingDate'
                        type='date'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Date'
                        defaultValue={getCurrentDate()}
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='openingDate'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6 card-footer d-flex justify-content'>
                <div className='table-container'>
                  <FieldArray
                    name='openingBalance'
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {values.openingBalance.map((openingBalance, index) => (
                            <div key={index} className='row mb-2 position-relative addon-group'>
                              <div className='col-md-3 mb-2 mb-md-0'>
                                <label className='form-label required fw-bold fs-6'>
                                  Account Name
                                </label>
                                <Field
                                  as='select'
                                  name={`openingBalance[${index}].coaId`}
                                  className='form-control form-control-lg form-control-solid'
                                  placeholder='Account Name'
                                >
                                  <option value=''>Select A/C Name</option>
                                  {ledger.map((item: any, i: any) => {
                                    console.log('ledger', ledger)
                                    return (
                                      <option key={i} value={item.id}>
                                        {item.headName}
                                      </option>
                                    )
                                  })}
                                </Field>

                                <ErrorMessage
                                  className='fieldError'
                                  name={`openingBalance[${index}].coaId`}
                                  render={(msg) => (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>{msg}</div>
                                    </div>
                                  )}
                                />
                              </div>

                              <div className='col-md-3'>
                                <label className='form-label fw-bold fs-6'>Debit</label>
                                <Field
                                  type='text'
                                  name={`openingBalance[${index}].debit`}
                                  className='form-control form-control-lg form-control-solid'
                                  placeholder='Debit'
                                />
                                <ErrorMessage
                                  className='fieldError'
                                  name={`openingBalance[${index}].debit`}
                                  component='div'
                                />
                              </div>

                              <div className='col-md-3'>
                                <label className='form-label fw-bold fs-6'>Credit</label>
                                <Field
                                  type='text'
                                  name={`openingBalance[${index}].credit`}
                                  className='form-control form-control-lg form-control-solid'
                                  placeholder='Credit'
                                />
                                <ErrorMessage
                                  className='fieldError'
                                  name={`openingBalance[${index}].credit`}
                                  component='div'
                                />
                              </div>
                              {index === 0 ? (
                                <div className='col-md-2 mb-2 mb-md-0'>
                                  <label className='form-label mt-14'></label>
                                  <Link to='#'>
                                    <div
                                      onClick={() =>
                                        arrayHelpers.push({
                                          coaId: '',
                                          debit: '',
                                          credit: '',
                                        })
                                      }
                                      className='btn btn-primary'
                                    >
                                      Add New Field
                                    </div>
                                  </Link>
                                </div>
                              ) : (
                                <div className='col-md-2 mb-2 mb-md-0'>
                                  <label className='form-label mt-14'></label>
                                  <Link to='#'>
                                    <div
                                      onClick={() => arrayHelpers.remove(index)}
                                      className='btn btn-danger'
                                    >
                                      Remove
                                    </div>
                                  </Link>
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      )
                    }}
                  />
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading ? 'Save Changes' : 'Please wait...'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default OpeningbalanceDetails
